export const facturas = (state) => {
  return state.facturas
}

export const estatus = (state) => {
  return state.estatus
}

export const subiendo = (state) => {
  return state.subiendo
}

export const cargando = (state) => {
  return state.cargando
}

export const validando = (state) => {
  return state.validando
}

export const guardando = (state) => {
  return state.guardando
}
