export const documentos = (state) => {
  return state.documentos
}

export const subiendo = (state) => {
  return state.subiendo
}

export const cargando = (state) => {
  return state.cargando
}
