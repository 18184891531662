export const setInfo = (state, info) => {
  state.info = info;
};

export const setLoading = (state, loading) => {
  state.loading = loading;
};

export const setContactos = (state, { contactos, tipoId }) => {
  switch (tipoId) {
    case 1:
      state.ventas = contactos;
      break;

    case 2:
      state.operaciones = contactos;
      break;
    case 3:
      state.administracion = contactos;
      break;
  }
};

export const setReferencias = (state, referencias) => {
  state.referencias = referencias;
};

export const setBancos = (state, bancos) => {
  state.bancos = bancos;
};

export const setCatalogos = (state, catalogos) => {
  state.catalogos = catalogos;
};
