import {createApp} from "vue"
import BootstrapVue3 from "bootstrap-vue-3"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faEdit,
  faTrash,
  faDownload,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./styles/style.bundle.css"
import "./styles/custom.css"
library.add(faEdit, faTrash, faDownload, faWindowClose)
createApp(App)
  .use(BootstrapVue3)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount("#app")
