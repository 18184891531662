export const loginUser = (state, { nombre, token }) => {
  if (token) {
    localStorage.setItem("vento-proveedores", token);
    state.token = token;
    state.nombre = nombre;
  }
};

export const logoutUser = (state) => {
  state.token = null;
  state.nombre = null;
  localStorage.removeItem("vento-proveedores");
};

export const setNombre = (state, nombre) => {
  state.nombre = nombre;
};
