import axios from "axios"

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("vento-proveedores")
  if (token) {
    config.headers = {Authorization: `Bearer ${token}`}
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [401, 419].includes(error.response.status)) {
      console.log("interceptor response")
    }
  }
)

export default api
