export default {
  info: null,
  referencias: [],
  ventas: {},
  operaciones: {},
  administracion: {},
  bancos: [],
  loading: false,
  catalogos: {
    tipos: [],
  },
};
