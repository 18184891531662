export default {
  component: () => import("../../../template/Default.vue"),
  children: [
    {
      path: "",
      name: "documentos",
      component: () => import("../components/Documentos"),
    },
  ],
}
