import api from "../../../api"

export const setDocumentosInit = ({commit}) => {
  commit("setDocumentos", [])
}

export const getDocumentos = async ({commit}) => {
  commit("setCargando", true)
  const {data} = await api.post("documentos")
  commit("setCargando", false)
  if (data.ok) {
    commit("setDocumentos", data.documentos)
  } else {
    commit("setDocumentos", [])
  }
}

export const subirDocumento = async ({commit}, forma) => {
  commit("setSubiendo", true)
  const {data} = await api.post("documentos-subir", forma)
  commit("setSubiendo", false)
  return data
}
