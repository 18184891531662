import api from "../../../api"

export const setFacturasInit = ({commit}) => {
  commit("setFacturas", [])
}

export const getFacturas = async ({commit}) => {
  commit("setCargando", true)
  const {data} = await api.post("facturas")
  commit("setCargando", false)
  if (data.ok) {
    commit("setFacturas", data.facturas)
    commit("setEstatus", data.estatus)
  } else {
    commit("setFacturas", [])
  }
}

export const setValidarXML = async ({commit}, datos) => {
  commit("setValidando", true)
  const {data} = await api.post("facturas-validar", datos)
  commit("setValidando", false)
  return data
}

export const setFacturar = async ({commit}, datos) => {
  commit("setGuardando", true)
  const {data} = await api.post("facturas-guardar", datos)
  commit("setGuardando", false)
  return data
}

export const setSubirDocumentoFactura = async ({commit}, datos) => {
  commit("setSubiendo", true)
  const {data} = await api.post("facturas-subir-documento", datos)
  commit("setSubiendo", false)

  return data
}
