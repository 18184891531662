export const setDocumentos = (state, documentos) => {
  state.documentos = documentos
}

export const setSubiendo = (state, subiendo) => {
  state.subiendo = subiendo
}

export const setCargando = (state, cargando) => {
  state.cargando = cargando
}
