export const info = (state) => {
  return state.info;
};

export const loading = (state) => {
  return state.loading;
};

export const ventas = (state) => {
  return state.ventas;
};

export const operaciones = (state) => {
  return state.operaciones;
};

export const administracion = (state) => {
  return state.administracion;
};

export const referencias = (state) => {
  return state.ventas;
};

export const bancos = (state) => {
  return state.bancos;
};

export const catalogos = (state) => {
  return state.catalogos;
};
