export default {
  component: () => import("../../../template/Default.vue"),
  children: [
    {
      path: "",
      name: "datos",
      component: () => import("../components/Datos"),
    },
  ],
};
