import api from "@/api"

export const setDatosInit = ({commit}) => {
  commit("setInfo", null)
  commit("setContactos", [])
  commit("setContactos", [])
  commit("setContactos", [])
  commit("setReferencias", [])
  commit("setBancos", [])
}

export const getInfo = async ({commit}) => {
  //commit("setLoading", true);
  const {data} = await api.post("info")
  //commit("setLoading", false);
  if (data.ok) {
    commit("setInfo", data.info)
    commit("setContactos", {contactos: data.ventas, tipoId: 1})
    commit("setContactos", {contactos: data.operaciones, tipoId: 2})
    commit("setContactos", {contactos: data.administracion, tipoId: 3})
    commit("setReferencias", data.referencias)
    commit("setBancos", data.bancos)
  } else {
    commit("setInfo", null)
    commit("setContactos", [])
    commit("setContactos", [])
    commit("setContactos", [])
    commit("setReferencias", [])
    commit("setBancos", [])
  }
}

export const getCatalogos = async ({commit}) => {
  const {data} = await api.post("catalogos")
  if (data.ok) {
    commit("setCatalogos", data.catalogos)
  }
}

export const setInfo = async ({commit}, datos) => {
  commit("setLoading", true)
  const {data} = await api.post("guardar", datos)
  if (data.ok) {
    commit("setInfo", data.info)
  }
  commit("setLoading", false)
  return data
}

export const setContacto = async ({commit}, datos) => {
  const {data} = await api.post("contactos-guardar", datos)
  if (data.ok) {
    commit("setContactos", {contactos: data.contactos, tipoId: data.tipo_id})
  }

  return data
}

export const setEliminarContacto = async ({commit}, id) => {
  const {data} = await api.post("contactos-eliminar", {id})
  if (data.ok) {
    commit("setContactos", {contactos: data.contactos, tipoId: data.tipo_id})
  }

  return data
}

export const setBancos = async ({commit}, datos) => {
  const {data} = await api.post("bancos-guardar", datos)
  if (data.ok) {
    commit("setBancos", data.bancos)
  }

  return data
}

export const setEliminarBancos = async ({commit}, id) => {
  const {data} = await api.post("bancos-eliminar", {id})
  if (data.ok) {
    commit("setBancos", data.bancos)
  }

  return data
}
