import {createRouter, createWebHistory} from "vue-router"
import VueBodyClass from "vue-body-class"
import dashboard from "../modules/dashboard/router"
import datos from "../modules/datos/router"
import login from "../modules/auth/router"
import isAuth from "../modules/auth/router/auth-guard"
import documentos from "../modules/documentos/router"
import facturas from "../modules/facturas/router"

const routes = [
  {
    path: "/",
    redirect: "/facturas",
  },
  {
    path: "/dashboard",
    beforeEnter: [isAuth],
    ...dashboard,
  },
  {
    path: "/datos",
    beforeEnter: [isAuth],
    ...datos,
  },
  {
    path: "/documentos",
    beforeEnter: [isAuth],
    ...documentos,
  },
  {
    path: "/facturas",
    beforeEnter: [isAuth],
    ...facturas,
  },
  {
    path: "/login",
    meta: {
      bodyClass:
        "body-login app-blank bgi-size-cover bgi-position-center bgi-no-repeat",
    },
    ...login,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next)
})

export default router
