export default {
  component: () => import("../../../template/SignIn"),
  children: [
    {
      path: "",
      name: "login",
      component: () => import("../components/Login"),
    },
  ],
}
