import {createStore} from "vuex"
import auth from "../modules/auth/store"
import datos from "../modules/datos/store"
import documentos from "../modules/documentos/store"
import facturas from "../modules/facturas/store"

const store = createStore({
  modules: {auth, datos, documentos, facturas},
})

export default store
